<template>
  <v-container class="settings-information--wrap" :class="{ editing }">
    <div class="blur-overlay" v-if="hidden" />
    <v-row class="mx-n6">
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.firstName') }}</div>
        <v-text-field outlined hide-details disabled dense :value="user.firstName" />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.lastName') }}</div>
        <v-text-field outlined hide-details disabled dense :value="user.lastName" />
      </v-col>

      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.dob') }}</div>
        <v-text-field
          outlined
          hide-details
          disabled
          dense
          :value="user.dateOfBirth | formatDate('YYYY-MM-DD', true)"
        />
      </v-col>

      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.email') }}</div>
        <v-text-field
          outlined
          dense
          v-model="editedUser.email"
          :disabled="!editing"
          :hide-details="!editing || !emailErrors.length"
          @blur="$v.editedUser.email.$touch()"
          @change="$v.editedUser.email.$touch()"
          :error-messages="emailErrors"
        />
      </v-col>

      <!-- PHONE NUMBERS -->
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.phoneNumberPrimary') }}</div>
        <v-text-field
          outlined
          dense
          v-model="editedUser.phone.primary"
          v-mask="phoneMask"
          :placeholder="phoneMask"
          :disabled="!editing"
          :hide-details="!editing || !primaryPhoneErrors.length"
          :error-messages="primaryPhoneErrors"
          @blur="$v.editedUser.phone.primary.$touch()"
          @change="$v.editedUser.phone.primary.$touch()"
          return-masked-value
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.phoneNumberSecondary') }}</div>
        <v-text-field
          outlined
          dense
          v-model="editedUser.phone.secondary"
          v-mask="phoneMask"
          :placeholder="phoneMask"
          :disabled="!editing"
          :hide-details="!editing || !secondaryPhoneErrors.length"
          :error-messages="secondaryPhoneErrors"
          @blur="$v.editedUser.phone.secondary.$touch()"
          @change="$v.editedUser.phone.secondary.$touch()"
          return-masked-value
        />
      </v-col>

      <!-- HEALTH CARD -->
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.hcn') }}</div>
        <v-row no-gutters>
          <v-col cols="2">
            <province-select
              outlined
              dense
              v-model="editedUser.healthCard.province"
              hide-details
              :disabled="!editing"
              @change="updateHealthCardMask"
            />
          </v-col>
          <v-col :cols="hcProvince === PROVINCE_NAMES.ON ? 7 : 9" class="pa-0 pl-2">
            <v-text-field
              outlined
              :disabled="!editing"
              dense
              v-model="editedUser.healthCard.number"
              v-mask="hcMask"
              :hide-details="!editing || !hinErrors.length"
              @blur="$v.editedUser.healthCard.number.$touch()"
              @change="$v.editedUser.healthCard.number.$touch()"
              :error-messages="hinErrors"
              return-masked-value
            />
          </v-col>
          <v-col cols="3" v-if="hcProvince === PROVINCE_NAMES.ON" class="pa-0 pl-2">
            <v-text-field
              outlined
              hide-details
              :disabled="!editing"
              dense
              v-model="editedUser.healthCard.versionNumber"
              v-mask="versionNumberMask"
              type="text"
              return-masked-value
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">{{ $t('main.hcnExpiry') }}</div>
        <date-picker
          :date="editedUser.healthCard.expiry"
          pickerColor="primary"
          dense
          @updateDate="editedUser.healthCard.expiry = $event"
          :hideDetails="!editing || !expiryErrors.length"
          :errors="expiryErrors"
          :min="expiryMin"
          :max="expiryMax"
          :disableMenu="!editing"
          :disabled="!editing"
        />
      </v-col>

      <!-- Home Clinic Location -->
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">
          {{ $t('patientPortal.settings.clinicLocation') }}
        </div>
        <v-text-field
          outlined
          hide-details
          disabled
          dense
          :value="user.location && user.location.name"
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <div class="settings-information__field--header">
          {{ $t('patientPortal.settings.clinicLocationAddress') }}
        </div>
        <v-text-field outlined hide-details disabled dense :value="generateAddress()" />
      </v-col>
      <!-- SAVE BUTTON -->
      <v-col
        :cols="$vuetify.breakpoint.xsOnly ? 12 : 12"
        class="justify-space-between d-flex"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <v-slide-x-transition mode="out-in">
          <v-btn
            class="mt-8 mt-md-4 mb-md-6 mr-md-2"
            rounded
            @click="toggleEdit"
            :disabled="saving"
            v-if="editing"
            key="discard"
          >
            {{ $t('patientPortal.settings.input.discardChanges') }}
          </v-btn>
          <v-btn class="mt-md-4 mb-md-6 mr-md-2" rounded @click="toggleEdit" v-else key="edit">
            {{ $t('patientPortal.settings.input.editInfo') }}
          </v-btn>
        </v-slide-x-transition>
        <v-slide-x-reverse-transition>
          <v-btn
            v-if="editing"
            @click="saveClick"
            rounded
            color="primary"
            contained
            type="submit"
            class="mt-4 mb-6"
            regular
            :disabled="$v.$invalid"
            :loading="saving"
          >
            {{ $t('patientPortal.settings.input.saveChanges') }}</v-btn
          >
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <validate-pin
      :open="validationOpen"
      attach="#settings-page"
      width="600px"
      :open-delay="100000"
      @success="onPinSuccess"
      autofocus
      :pin-message="$t('patientPortal.settings.notification.updateInfo.validatePin.title')"
    />
  </v-container>
</template>
<style lang="scss" scoped>
@import '../../styles/patient-portal.scss';
.settings-information--wrap {
  .settings-information__field--header {
    text-transform: uppercase !important;
    font-weight: bold;
    color: #525252;
    font-size: 0.8em;
    margin-bottom: $spacer * 2;
  }
}

::v-deep {
  .v-text-field {
    .v-input__slot {
      background-color: white;
    }
    fieldset {
      transition: border 0.2s;
    }
  }
}
.editing {
  ::v-deep {
    .v-input:not(.v-input--is-disabled, .error--text) {
      fieldset {
        border: 1px solid var(--v-primary-base);
      }
    }
  }
}

.edit-button {
  transition: all 0.2s;
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: -8px;
  height: 100%;
  width: calc(100% + 16px);
  backdrop-filter: blur(8px);
  border-radius: inherit;
  z-index: 1;
}

@media all and (max-width: 330px) {
  .settings-information--wrap {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
<script>
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import { validateHin } from '../../utils/healthcard';
import { phone } from '../../utils/validation';
import { ENABLE_CHECKSUM_ENV } from '../../constants/feature-flag';
import { NODE_ENV } from '../../constants/env';
import provinces, { PROVINCE_NAMES } from '../../constants/provinces';
import { phone as phoneMask } from '../../constants/masks';
import ValidatePin from './ValidatePin.vue';
import ProvinceSelect from '../ProvinceSelect.vue';
import DatePicker from '../DatePicker.vue';
import { DATE_FORMAT } from '../../constants/moment';

export default {
  data() {
    return {
      editing: false,
      hcMask: null,
      phoneMask,
      provinces,

      editedUser: {
        email: null,
        phone: {
          primary: null,
          secondary: null,
        },
        healthCard: {},
      },
      PROVINCE_NAMES,
      validationOpen: false,
      saving: false,
      versionNumberMask: 'AA',
    };
  },
  props: {
    hidden: Boolean,
  },
  components: {
    DatePicker,
    ProvinceSelect,
    ValidatePin,
  },
  computed: {
    healthCard() {
      return this.editedUser?.healthCard;
    },

    hcProvince() {
      return this.editedUser.healthCard?.province;
    },
    hcNumber() {
      return this.editedUser.healthCard?.number;
    },
    emailErrors() {
      if (!this.editing) return [];
      const errors = [];
      if (!this.$v.editedUser.email.email)
        errors.push(this.$t('patientPortal.settings.validation.invalidEmail'));
      return errors;
    },
    expiryErrors() {
      if (!this.editing) return [];
      const errors = [];
      if (!this.$v.editedUser.healthCard.expiry.required)
        errors.push(this.$t('patientPortal.settings.validation.required'));
      return errors;
    },
    expiryMax() {
      return this.$store.getters.toTimezone().add(10, 'years').format(DATE_FORMAT);
    },
    expiryMin() {
      return this.$store.getters.toTimezone().subtract(10, 'years').format(DATE_FORMAT);
    },
    hinErrors() {
      if (!this.editing) return [];
      const errors = [];
      if (this.$v.editedUser.healthCard.number.$invalid)
        errors.push(this.$t('patientPortal.settings.validation.invalidHealthcard'));
      return errors;
    },

    primaryPhoneErrors() {
      if (!this.editing) return [];
      const errors = [];
      if (!this.$v.editedUser.phone.primary.required)
        errors.push(this.$t('patientPortal.settings.validation.required'));
      if (!this.$v.editedUser.phone.primary.phone)
        errors.push(this.$t('patientPortal.settings.validation.invalidPhone'));

      return errors;
    },
    secondaryPhoneErrors() {
      if (!this.editing) return [];
      const errors = [];

      if (!this.$v.editedUser.phone.secondary.phone)
        errors.push(this.$t('patientPortal.settings.validation.invalidPhone'));

      return errors;
    },
    user() {
      if (this.hidden)
        return {
          healthCard: {},
          phone: {},
          location: {},
        };
      return this.$store.getters['patientPortal/currentUser'];
    },
  },
  methods: {
    toggleEdit() {
      this.editing = !this.editing;
      if (!this.editing) {
        this.resetEditedUser();
      }
    },
    async openPinValidate() {
      this.validationOpen = true;
    },
    async resetEditedUser() {
      const user = JSON.parse(JSON.stringify(this.$store.getters['patientPortal/currentUser']));

      this.editedUser = user;
      this.editedUser.phone = user.phone || {};
      this.editedUser.location = user.location || {};
      this.editedUser.healthCard = user.healthCard || {};
      this.editedUser.healthCard.province = user.healthCard?.province;
      this.editedUser.healthCard.number = user.healthCard?.number;

      this.updateHealthCardMask();

      this.phoneMask = '';
      this.versionNumberMask = '';

      this.$nextTick(() => {
        this.phoneMask = phoneMask;
        this.versionNumberMask = 'AA';
      });
    },
    async saveEditedInputs() {
      try {
        const updates = {};
        if (this.editedUser.email !== this.user.email) {
          updates.email = this.editedUser.email;
        }
        if (this.editedUser.phone.primary !== this.user.phone.primary) {
          updates.phone = {
            ...updates.phone,
            primary: this.editedUser.phone.primary,
          };
        }
        if (this.editedUser.phone.secondary !== this.user.phone.secondary) {
          updates.phone = {
            ...updates.phone,
            secondary: this.editedUser.phone.secondary,
          };
        }
        if (this.editedUser.healthCard.number !== this.user.healthCard.number) {
          if (!updates.healthCard) updates.healthCard = {};
          updates.healthCard.number = this.editedUser.healthCard.number
            .replaceAll(' ', '')
            .replaceAll('-', '');
        }

        if (this.editedUser.healthCard.expiry !== this.user.healthCard.expiry) {
          if (!updates.healthCard) updates.healthCard = {};
          updates.healthCard.expiry = this.$store.getters
            .toUTC(this.editedUser.healthCard.expiry)

            .unix();
        }

        if (this.editedUser.healthCard.versionNumber !== this.user.healthCard.versionNumber) {
          if (!updates.healthCard) updates.healthCard = {};
          updates.healthCard.versionNumber = this.editedUser.healthCard.versionNumber;
        }

        if (this.editedUser.healthCard.province !== this.user.healthCard.province) {
          if (!updates.healthCard) updates.healthCard = {};
          updates.healthCard.province = this.editedUser.healthCard.province;
        }
        this.saving = true;
        await this.$store.dispatch('patientPortal/updateUserInfo', { updates });
        this.editing = false;
      } finally {
        this.saving = false;
      }
    },
    async saveClick() {
      this.openPinValidate();
    },
    onPinSuccess() {
      this.validationOpen = false;
      this.saveEditedInputs();
    },
    updateHealthCardMask() {
      const { province } = this.editedUser.healthCard;
      this.hcMask = null;
      this.$nextTick(() => {
        this.hcMask = provinces.find(({ key }) => key === province)?.format;
      });
    },
    generateAddress() {
      if (!this.user?.location?.address) {
        return this.$t('patientPortal.settings.clinicNoAddress');
      }

      const { address } = this.user.location;
      const addressParts = [];

      if (address.suite) {
        addressParts.push(address.suite);
      }
      if (address.streetNumber && address.streetName) {
        addressParts.push(`${address.streetNumber} ${address.streetName}`);
      }
      if (address.city) {
        addressParts.push(address.city);
      }
      if (address.province) {
        addressParts.push(address.province);
      }
      if (address.postalCode) {
        addressParts.push(address.postalCode);
      }

      return addressParts.length > 0
        ? addressParts.join(', ')
        : this.$t('patientPortal.settings.clinicNoAddress');
    },
  },

  validations() {
    return {
      editedUser: {
        email: {
          email,
        },
        phone: {
          primary: {
            phone,
            required,
          },
          secondary: {
            phone,
          },
        },
        healthCard: {
          number: {
            valid: (val) => {
              if (!this.hcProvince) return true;
              const checkSum =
                ENABLE_CHECKSUM_ENV.includes(NODE_ENV) &&
                this.editedUser.healthCard?.province === PROVINCE_NAMES.ON;

              return validateHin(val, this.editedUser.healthCard?.province, checkSum);
            },
          },
          expiry: {
            required: requiredIf(() => !!this.editedUser.healthCard.number),
          },
        },
      },
    };
  },
  watch: {
    hidden: {
      immediate: true,
      handler(val) {
        if (val) return;
        this.resetEditedUser();
      },
    },
  },
};
</script>
