<template>
  <v-select
    :items="provinces"
    v-bind="$attrs"
    class="province-select"
    v-model="editedValue"
    item-value="key"
    @change="$emit('change')"
  >
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-icon style="width: 40px">
          <img :src="flag(item.key)" width="100%" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(`provinces.${item.key}`) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <img :src="flag(item.key)" style="border-radius: inherit" class="province__flag" />
    </template>
  </v-select>
</template>
<style lang="scss" scoped>
::v-deep {
  .v-input__append-inner {
    display: none !important;
  }
  .v-input__slot {
    padding: 0px !important;
  }
  .v-select__slot {
    border-radius: inherit;
  }
  .v-select__selections {
    padding: 0px !important;
    border-radius: inherit;
  }
}
.province__flag {
  width: calc(100% - 2px);
  margin-left: 1px;
}
</style>
<script>
import * as flags from '../assets/flags/index';
import provinces from '../constants/provinces';

export default {
  data() {
    return {
      provinces,
    };
  },
  props: {
    value: String,
  },
  computed: {
    editedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    flag(provinceName) {
      try {
        return flags[provinceName];
      } catch {
        return '';
      }
    },
  },
};
</script>
