<template>
  <div class="d-flex flex-column" id="settings-page">
    <content-title>
      <template slot="title"> {{ $t('patientPortal.settings.title') }} </template>
      <template slot="subtitle">{{ $t('patientPortal.settings.subtitle') }} </template>
    </content-title>
    <v-tabs v-model="tab" class="flex-grow-0" :show-arrows="false">
      <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <div class="settings-content flex-grow-1">
      <keep-alive>
        <component :is="tabs[tab].component" class="mt-4" :hidden="validationRequired" />
      </keep-alive>
    </div>
    <validate-pin
      :open="validationOpen"
      attach="#settings-page"
      width="600px"
      :open-delay="100000"
      @success="
        () => {
          validationOpen = false;
          validationRequired = false;
        }
      "
      autofocus
    />
  </div>
</template>
<style lang="scss" scoped>
.settings-content {
  position: relative;
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: -8px;
  height: 100%;
  width: calc(100% + 16px);
  backdrop-filter: blur(8px);
  border-radius: inherit;
  z-index: 1;
}

::v-deep .v-slide-group__prev {
  display: none !important;
}
</style>
<script>
import ContentTitle from '../../components/ContentTitle.vue';
import SettingsInformation from '../../components/PatientPortal/SettingsInformation.vue';

import ValidatePin from '../../components/PatientPortal/ValidatePin.vue';

export default {
  components: {
    ContentTitle,

    SettingsInformation,
    SettingsLogs: () => import('../../components/PatientPortal/SettingsLogs.vue'),
    ValidatePin,
  },
  data() {
    return {
      PIN_VMASK: '####',
      tab: 0,
      tabs: [
        {
          title: this.$t('patientPortal.settings.tabs.info'),
          component: 'settings-information',
        },
        {
          title: this.$t('patientPortal.settings.tabs.logs'),
          component: 'settings-logs',
        },
      ],
      validationOpen: false,

      validationRequired: true,
    };
  },
  methods: {
    validateUser() {
      setTimeout(() => {
        this.validationOpen = true;
      }, 500);
    },
  },
  mounted() {
    this.validateUser();
  },
};
</script>
