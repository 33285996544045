<template>
  <v-dialog
    :value="open"
    hide-overlay
    attach="#settings-page"
    class="validation--dialog"
    width="600px"
    persistent
  >
    <v-card class="d-flex flex-column align-center pb-4">
      <v-card-title class="font-weight-bold text-center validation__title mb-1">{{
        pinMessage
      }}</v-card-title>
      <div class="d-flex flex-column align-center" style="max-width: 250px">
        <v-card-text class="pb-0">
          <password-input
            label="PIN"
            name="PIN"
            outlined
            :mask="PIN_VMASK"
            v-model="pin"
            :error-messages="pinError ? [pinError] : null"
            @input="
              (e) => {
                pin = e;
                pinError = null;
              }
            "
            :autofocus="autofocus"
            v-if="open"
          />
        </v-card-text>

        <v-card-actions class="full-width px-4">
          <v-btn
            color="primary"
            block
            large
            @click="validatePin"
            :loading="validating"
            :disabled="validating || $v.pin.$invalid"
            >{{ $t('main.submit') }}</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
::v-deep .validation__title {
  font-size: 1em !important;
  word-break: keep-all;
  line-height: 1.4em;
}
</style>
<script>
import { required } from 'vuelidate/lib/validators';
import i18n from '../../plugins/i18n';
import PasswordInput from '../PasswordInput.vue';

export default {
  props: {
    autofocus: Boolean,
    open: Boolean,
    pinMessage: {
      default: i18n.t('patientPortal.validatePin.title'),
      type: String,
    },
  },
  components: {
    PasswordInput,
  },
  data() {
    return {
      pin: null,
      pinError: null,
      PIN_LENGTH: 4,
      PIN_VMASK: '####',
      validating: false,
    };
  },
  methods: {
    async validatePin() {
      this.validating = true;

      const tenantUrl = localStorage.getItem('tenantUrl') ?? 'sentry-health';
      try {
        const { id: memberId } = this.$store.getters['patientPortal/currentUser'];
        const { data } = await this.$apollo.mutate({
          mutation: await this.$store.getters.getMutation('LoginMemberWithPin'),
          variables: {
            tenantUrl,
            memberId,
            pin: this.pin,
          },
        });
        const { loginMemberWithPin: token } = data;
        if (token) this.$emit('success');
      } catch {
        this.pinError = this.$t('patientPortal.login.errors.invalidPin');
        this.pin = null;
      }
      this.validating = false;
    },
  },
  validations() {
    return {
      pin: {
        required,
        checkLength: (val) => {
          return val?.length === this.PIN_LENGTH;
        },
        noError: () => {
          return !this.pinError;
        },
      },
    };
  },
  watch: {
    open() {
      this.pin = null;
    },
  },
};
</script>
